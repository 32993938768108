import type { ReactNode } from 'react';
import { useInView } from 'react-intersection-observer';
import styled from 'styled-components';

// TypeScriptの型定義 (今回は無視してOK)
type FadeAnimationType = {
  children: ReactNode;
  delay?: number;
  rootMargin?: string;
  initialOpacity?: number;
  noTranslate?: boolean;
  transition?: string;
};

/**
 * ★inView(trueかfalse)で受け取り、それに応じてcssを切り替える
 */
const SFadeElem = styled.span<{ inView: boolean; noTranslate?: boolean }>`
  display: inline-block;
  transition: all 0.75s ease-in-out;

  //opacityをtrueなら1、falseなら0とする
  opacity: 0;
  width: 100%;
  opacity: ${(props) => (props.inView ? 1 : 0)};
  transform: ${(props) =>
    props.inView && !props.noTranslate
      ? 'translateY(0px)'
      : 'translateY(24px)'};
`;

export const FadeUpAnimation = (props: FadeAnimationType) => {
  const { children, delay, rootMargin, transition } = props;

  const { ref, inView } = useInView({
    rootMargin: rootMargin || '-150px 0px 0px 0px',
    triggerOnce: true,
  });

  return (
    <SFadeElem
      noTranslate={props.noTranslate}
      inView={inView}
      ref={ref}
      style={{
        opacity: inView ? 1 : props.initialOpacity || 0,
        transform:
          inView || props.noTranslate ? 'translateY(0px)' : 'translateY(24px)',
        transition,
        transitionDelay: `${delay}s`,
      }}
    >
      {children}
    </SFadeElem>
  );
};
