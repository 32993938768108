import type { ReactNode } from 'react';
import { useInView } from 'react-intersection-observer';
import styled from 'styled-components';

// TypeScriptの型定義 (今回は無視してOK)
type FadeAnimationType = {
  children: ReactNode;
  delay?: number;
  transition?: string;
};

/**
 * ★inView(trueかfalse)で受け取り、それに応じてcssを切り替える
 */
const SFadeElem = styled.span<{ inView: boolean; forceDisplay?: boolean }>`
  transition: all 1.75s ease-in-out;

  //opacityをtrueなら1、falseなら0とする
  opacity: 0;
  opacity: ${(props) => (props.inView ? 1 : 0)};
  transform: ${(props) =>
    props.inView ? 'translateX(0px)' : 'translateX(24px)'};
  flex-shrink: 0;
`;

export const FadeLeftAnimation = (props: FadeAnimationType) => {
  const { children, delay, transition } = props;

  const { ref, inView } = useInView({
    rootMargin: '-150px 0px 0px 0px',
    triggerOnce: true,
  });

  return (
    <SFadeElem
      inView={inView}
      ref={ref}
      style={{
        opacity: inView ? 1 : 0,
        transform: inView ? 'translateX(0px)' : 'translateX(24px)',
        transition,
        transitionDelay: `${delay}s`,
      }}
    >
      {children}
    </SFadeElem>
  );
};
