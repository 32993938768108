import type { GetServerSideProps } from 'next';
import { serverSideTranslations } from 'next-i18next/serverSideTranslations';

import { TopPage } from '@/components/pages/TopPage';
import { TopPageSP } from '@/components/pages/TopPageSP';
import { Meta } from '@/layouts/Meta';
import { client } from '@/libs/client';
import { Main } from '@/templates/Main';

import type { News } from './news';
import type { Service } from './services';

export type Information = {
  id: string;
  createdAt: string;
  updatedAt: string;
  publishedAt: string;
  revisedAt: string;

  title: string;
  content: string;
  category: {
    id: string;
    createdAt: string;
    updatedAt: string;
    publishedAt: string;
    revisedAt: string;
    name: string;
  };
};

const Index = ({ services, news }: { services: Service[]; news: News[] }) => {
  return (
    <Main
      meta={
        <Meta
          title="税理士法人奏共同会計事務所｜資本戦略専門の税理士法人"
          description="税理士法人奏共同会計事務所は、資本戦略の実行支援を専門とする日本唯一の税理士法人です。組織再編、事業承継、M&A、国際資本戦略など、企業の成長ステージに応じた先進的なソリューションを提供。高い職業倫理と専門性を持つプロフェッショナル集団として、お客様の経営課題解決を支援します。"
          image="/ogp-images/ogp.jpg"
        />
      }
      desktop={<TopPage services={services} news={news} />}
      mobile={<TopPageSP services={services} news={news} />}
    />
  );
};
export const getServerSideProps: GetServerSideProps<{
  news: News[];
  services: Service[];
}> = async ({ locale }) => {
  const serviceRes = await client.getList<Service>({
    endpoint: 'services',
    queries: { limit: 6 },
  });

  const newsRes = await client.getList<News>({
    endpoint: 'news',
    queries: {
      limit: 3,
    },
  });

  const services = serviceRes.contents;
  const news = newsRes.contents;

  return {
    props: {
      services,
      news,
      ...(await serverSideTranslations(locale!, ['common'])),
    },
  };
};

export default Index;
